<template>
  <div class="social">
    <div class="social-item">
      <div class="social-item__icon">
        <img src="@/assets/svg/instagram.svg" alt="Instagram" />
      </div>
      <div>
        <div class="social-item__title">Instagram</div>
        <div class="social-item__text">
          <a href="https://instagram.com/solovjevrv" target="_blank"
            >@solovjevrv</a
          >
        </div>
      </div>
    </div>
    <div class="social-item">
      <div class="social-item__icon">
        <img src="@/assets/svg/telegram.svg" alt="Twitter" />
      </div>
      <div>
        <div class="social-item__title">Telegram</div>
        <div class="social-item__text">
          <a href="https://t.me/solovjevrv" target="_blank">@solovjevrv</a>
        </div>
      </div>
    </div>
    <div class="social-item">
      <div class="social-item__icon">
        <img src="@/assets/svg/linkedin.svg" alt="Linkedin" />
      </div>
      <div>
        <div class="social-item__title">Linkedin</div>
        <div class="social-item__text">
          <a
            href="https://www.linkedin.com/in/roman-solovyov-507494195"
            target="_blank"
            >roman-solovyov-507494195</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialComponent',
};
</script>
