<template>
  <div class="header">
    <div class="header-avatar">
      <img
        class="header-avatar__img"
        src="@/assets/img/avatar-large.jpg"
        alt="Avatar"
      />
    </div>
    <h2 class="header-fio">{{ $t('fio') }}</h2>
    <span class="header-position">Frontend Developer</span>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
};
</script>
