<template>
  <div class="wrap">
    <StepLineComponent></StepLineComponent>
    <div class="projects">
      <h2 class="projects__title">
        {{ $t('projectsTitle') }}
      </h2>
      <div class="row mt-4 mb-3">
        <div class="col-lg-6 col-md-12">
          <div class="projects-card">
            <div class="projects-card__wrap">
              <img
                class="projects-card__logo"
                src="@/assets/svg/documeds-logo-2.svg"
                alt="Documeds"
              />
              <div class="projects-card__desc">
                <span>Documeds</span>
                <p>{{ $t('projectDesc1') }}</p>
              </div>
            </div>
            <div class="projects-card__link">
              <img src="@/assets/svg/projects-icon.svg" alt="Link" />
              <a href="https://documeds.ru">https://documeds.ru</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 mt-3 mt-lg-0">
          <div class="projects-card">
            <div class="projects-card__wrap">
              <img
                class="projects-card__logo"
                src="@/assets/svg/parus-logo.svg"
                alt="Documeds"
              />
              <div class="projects-card__desc">
                <span>{{ $t('projectName2') }}</span>
                <p>{{ $t('projectDesc2') }}</p>
              </div>
            </div>
            <div class="projects-card__link">
              <img src="@/assets/svg/projects-icon.svg" alt="Link" />
              <a href="https://parus-ryazan.ru">https://parus-ryazan.ru</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepLineComponent from './StepLineComponent.vue';
export default {
  name: 'ProjectsComponent',
  components: {
    StepLineComponent,
  },
};
</script>
