<template>
  <div class="sidebar">
    <HeaderComponent></HeaderComponent>
    <DividerComponent></DividerComponent>
    <ContactsComponent></ContactsComponent>
    <DividerComponent></DividerComponent>
    <SocialComponent></SocialComponent>
    <DividerComponent></DividerComponent>
    <LanguagesComponent></LanguagesComponent>
  </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import DividerComponent from './DividerComponent.vue';
import ContactsComponent from './ContactsComponent.vue';
import SocialComponent from './SocialComponent.vue';
import LanguagesComponent from './LanguagesComponent.vue';

export default {
  name: 'SidebarComponent',
  components: {
    HeaderComponent,
    DividerComponent,
    ContactsComponent,
    SocialComponent,
    LanguagesComponent,
  },
};
</script>
