import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  ru: {
    fio: 'Роман Соловьёв',
    contacts: 'Контакты',
    social: 'Социальные сети',
    languages: 'Языки',
    email: 'Эл. почта',
    website: 'Сайт',
    phone: 'Телефон',
    address: 'Адрес',
    addressValue: 'Россия, Рязань',
    langRu: 'Русский',
    langRuStatus: 'Родной',
    langEn: 'Английский',
    langEnStatus: 'Проффессиональный',
    experienceTitle: 'Опыт работы',
    position1: 'Frontend Developer, Team Lead',
    company1: 'Documeds, ООО Парус Рязань',
    date1: 'Май 2022 - н. в.',
    location1: 'Россия, Рязань',
    position2: 'Frontend Developer',
    company2: 'Freelance',
    date2: 'Июль 2020 - Июнь 2022',
    location2: 'Россия, Рязань',
    position3: 'Frontend Developer',
    company3: 'Kaimana Studio',
    date3: 'Сентябрь 2019 - Июль 2020',
    location3: 'Россия, Рязань',
    skillsTitle: 'Навыки',
    skill1: 'Разработка',
    skill2: 'Управление',
    management: 'Управление командой',
    management1: 'Проектирование',
    management2: 'Разработка',
    management3: 'Ревью',
    management4: 'Разработка UI',
    educationTitle: 'Образование',
    courseName1: 'Vue JS и Vuex',
    courseDate1: 'Январь 2023',
    courseName2: 'Курсы Системного анализа',
    courseDate2: 'Май 2022',
    courseName3: 'JavaScript - Полный курс',
    courseDate3: 'Март 2023',
    courseName4: 'Управление проектами',
    courseDate4: 'Май 2022',
    courseName5: 'Python для начинающих',
    courseDate5: 'Январь 2021',
    courseName6: 'Веб-разработчик',
    courseDate6: 'Май 2022',
    projectsTitle: 'Последние проекты',
    projectDesc1:
      'Медицинская информационная система для коммерческих клиник. Стартап. Коммерческая разработка.',
    projectName2: 'ГИС СУЗ РО «Парус»',
    projectDesc2:
      'Медицинская информационная система. Госконтракт Министерства здравоохранения Рязанской области.',
    toolsTitle: 'Инструменты',
    tool1: 'Редактор кода',
    tool2: 'Платформа хранения кода ',
    tool3: 'Дизайн интерфейсов',
    tool4: 'Управление проектом',
    tool5: 'Управление API',
    tool6: 'JavaScript Framework',
    tool7: 'Работа c API',
    tool8: 'Версионирование',
    tool9: 'Документация',
    tool10: 'База данных',
  },
  en: {
    fio: 'Roman Solovyov',
    contacts: 'Contacts',
    social: 'Socials',
    languages: 'Languages',
    email: 'Email',
    website: 'Website',
    phone: 'Phone',
    address: 'Address',
    addressValue: 'Russia, Ryazan',
    langRu: 'Russian',
    langRuStatus: 'Native',
    langEn: 'English',
    langEnStatus: 'Professional working',
    experienceTitle: 'Experience',
    position1: 'Frontend Developer, Product Manager ',
    company1: 'Documeds, Parus Ryazan',
    date1: 'May 2022 - Now',
    location1: 'Ryazan, Russia',
    position2: 'Frontend Developer',
    company2: 'Freelance',
    date2: 'Jul 2020 - Jun 2022',
    location2: 'Ryazan, Russia',
    position3: 'Frontend Developer',
    company3: 'Kaimana Studio',
    date3: 'Sep 2019 - Jul 2020',
    location3: 'Ryazan, Russia',
    skillsTitle: 'Skills',
    skill1: 'Development',
    skill2: 'Management',
    management: 'Team Management',
    management1: 'Wireframing',
    management2: 'Prototyping',
    management3: 'Review',
    management4: 'Desing System',
    educationTitle: 'Education',
    courseName1: 'Vue JS and Vuex',
    courseDate1: 'Jan 2023',
    courseName2: 'System Analyst',
    courseDate2: 'Май 2022',
    courseName3: 'JavaScript - Full Course',
    courseDate3: 'March 2023',
    courseName4: 'Project Management',
    courseDate4: 'May 2022',
    courseName5: 'Python for Beginners',
    courseDate5: 'Jun 2021',
    courseName6: 'Web Technologies',
    courseDate6: 'May 2022',
    projectsTitle: 'Latest projects',
    projectDesc1:
      'Medical Health Information System for commercial clinics and centers. Commercial development. Startup. ',
    projectName2: 'MIS Parus',
    projectDesc2:
      'Medical Health Information System for Ministry of Health of the Ryazan Region. Government development contract ',
    toolsTitle: 'Tools',
    tool1: 'Source Code Editor',
    tool2: 'DevSecOps Platform',
    tool3: 'Design Interface',
    tool4: 'Project Management',
    tool5: 'API Manager',
    tool6: 'JavaScript Framework',
    tool7: 'HTTP Requests',
    tool8: 'Version Control System ',
    tool9: 'Project Documentation',
    tool10: 'Data base',
  },
};

const i18n = new VueI18n({
  lazy: true,
  locale: 'ru', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
