<template>
  <div class="wrap">
    <StepLineComponent></StepLineComponent>
    <div class="experience">
      <h2 class="experience__title">{{ $t('experienceTitle') }}</h2>
      <div class="experience-list mt-4">
        <div class="experience-item">
          <div class="experience-wrap">
            <div class="experience-item__logo">
              <img src="@/assets/svg/documeds-logo.svg" alt="Documeds" />
            </div>
            <div class="experience-item__content">
              <p>{{ $t('position1') }}</p>
              <span>{{ $t('company1') }}</span>
            </div>
          </div>
          <div class="experience-item__time-geo">
            <p>{{ $t('date1') }}</p>
            <span
              ><img src="@/assets/svg/location.svg" alt="Geo" srcset="" />{{
                $t('location1')
              }}</span
            >
          </div>
        </div>
        <div class="experience-item">
          <div class="experience-wrap">
            <div class="experience-item__logo">
              <img src="@/assets/svg/fl-logo.svg" alt="Documeds" />
            </div>
            <div class="experience-item__content">
              <p>{{ $t('position2') }}</p>
              <span>{{ $t('company2') }}</span>
            </div>
          </div>
          <div class="experience-item__time-geo">
            <p>{{ $t('date2') }}</p>
            <span
              ><img src="@/assets/svg/location.svg" alt="Geo" />{{
                $t('location2')
              }}</span
            >
          </div>
        </div>
        <div class="experience-item">
          <div class="experience-wrap">
            <div class="experience-item__logo">
              <img src="@/assets/svg/kaimana-logo.svg" alt="Documeds" />
            </div>
            <div class="experience-item__content">
              <p>{{ $t('position3') }}</p>
              <span>{{ $t('company3') }}</span>
            </div>
          </div>
          <div class="experience-item__time-geo">
            <p>{{ $t('date3') }}</p>
            <span
              ><img src="@/assets/svg/location.svg" alt="Geo" />{{
                $t('location3')
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepLineComponent from './StepLineComponent.vue';
export default {
  name: 'ExperienceComponent',
  components: {
    StepLineComponent,
  },
};
</script>
