var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts"},[_c('h6'),_c('div',{staticClass:"contacts-item"},[_vm._m(0),_c('div',[_c('div',{staticClass:"contacts-item__title"},[_vm._v(_vm._s(_vm.$t('email')))]),_vm._m(1)])]),_c('div',{staticClass:"contacts-item"},[_vm._m(2),_c('div',[_c('div',{staticClass:"contacts-item__title"},[_vm._v(_vm._s(_vm.$t('website')))]),_vm._m(3)])]),_c('div',{staticClass:"contacts-item"},[_vm._m(4),_c('div',[_c('div',{staticClass:"contacts-item__title"},[_vm._v(_vm._s(_vm.$t('phone')))]),_vm._m(5)])]),_c('div',{staticClass:"contacts-item"},[_vm._m(6),_c('div',[_c('div',{staticClass:"contacts-item__title"},[_vm._v(_vm._s(_vm.$t('address')))]),_c('div',{staticClass:"contacts-item__text"},[_c('p',[_vm._v(_vm._s(_vm.$t('addressValue')))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-item__icon"},[_c('img',{attrs:{"src":require("@/assets/svg/mail.svg"),"alt":"Email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-item__text"},[_c('a',{attrs:{"href":"mailto:solovjevrv@gmail.com","target":"_blank"}},[_vm._v("solovjevrv@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-item__icon"},[_c('img',{attrs:{"src":require("@/assets/svg/website.svg"),"alt":"Website"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-item__text"},[_c('a',{attrs:{"href":"https://solovyovroman.ru","target":"_blank"}},[_vm._v("https://solovyovroman.ru")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-item__icon"},[_c('img',{attrs:{"src":require("@/assets/svg/phone.svg"),"alt":"Phone"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-item__text"},[_c('a',{attrs:{"href":"tel:+79105010229","target":"_blank"}},[_vm._v("+7 910 501 02 29")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contacts-item__icon"},[_c('img',{attrs:{"src":require("@/assets/svg/location.svg"),"alt":"Address"}})])
}]

export { render, staticRenderFns }