<template>
  <div id="app">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-4 col-md-5">
          <select
            class="select-lang"
            v-model="selectLang"
            @change="changeLocale(selectLang)"
          >
            <option
              v-for="item in langs"
              :key="item.index"
              :value="item.value"
              :label="item.label"
            >
              {{ item.label }}
            </option>
          </select>
          <SidebarComponent></SidebarComponent>
        </div>
        <div class="col-lg-8 col-md-7">
          <MainComponent></MainComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarComponent from '@/components/SidebarComponent.vue';
import MainComponent from '@/components/MainComponent.vue';
import i18n from '@/plugins/i18n';

export default {
  name: 'App',
  components: {
    SidebarComponent,
    MainComponent,
  },
  data() {
    return {
      selectLang: 'ru',
      langs: [
        {
          value: 'en',
          label: 'EN',
        },
        {
          value: 'ru',
          label: 'RU',
        },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
};
</script>
