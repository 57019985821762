<template>
  <div class="wrap">
    <StepLineComponent></StepLineComponent>
    <div class="tools">
      <h2 class="tools__title">
        {{ $t('toolsTitle') }}
      </h2>
      <div class="row mt-4 mb-3">
        <div class="col-lg-4 col-sm-6">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/vsc.svg"
              alt="Visual Studio Code"
            />
            <span class="tools-card__title">Visual Studio Code</span>
            <p class="tools-card__desc">{{ $t('tool1') }}</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mt-3 mt-sm-0">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/gitlab.svg"
              alt="Gitlab"
            />
            <span class="tools-card__title">Gitlab</span>
            <p class="tools-card__desc">{{ $t('tool2') }}</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mt-3 mt-xs-0 mt-lg-0">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/notion.svg"
              alt="Notion"
            />
            <span class="tools-card__title">Notion</span>
            <p class="tools-card__desc">{{ $t('tool9') }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 mt-3">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/swagger.svg"
              alt="Swagger"
            />
            <span class="tools-card__title">Swagger</span>
            <p class="tools-card__desc">{{ $t('tool5') }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 mt-3">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/vue.svg"
              alt="Vue"
            />
            <span class="tools-card__title">Vue</span>
            <p class="tools-card__desc">{{ $t('tool6') }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 mt-3">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/postman.svg"
              alt="Postman"
            />
            <span class="tools-card__title">Postman</span>
            <p class="tools-card__desc">{{ $t('tool7') }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 mt-3">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/git.svg"
              alt="Git"
            />
            <span class="tools-card__title">Git</span>
            <p class="tools-card__desc">{{ $t('tool8') }}</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mt-3">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/figma.svg"
              alt="Figma"
            />
            <span class="tools-card__title">Figma</span>
            <p class="tools-card__desc">{{ $t('tool3') }}</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mt-3">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/jira.svg"
              alt="Jira"
            />
            <span class="tools-card__title">Jira Software</span>
            <p class="tools-card__desc">{{ $t('tool4') }}</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 mt-3">
          <div class="tools-card">
            <img
              class="tools-card__logo"
              src="@/assets/svg/pg.svg"
              alt="Jira"
            />
            <span class="tools-card__title">PostgreSQL</span>
            <p class="tools-card__desc">{{ $t('tool10') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepLineComponent from './StepLineComponent.vue';
export default {
  name: 'ToolsComponent',
  components: {
    StepLineComponent,
  },
};
</script>
