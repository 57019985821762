var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('StepLineComponent'),_c('div',{staticClass:"skills"},[_c('h2',{staticClass:"skills__title"},[_vm._v(" "+_vm._s(_vm.$t('skillsTitle'))+" ")]),_c('div',{staticClass:"skills-list mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-4 col-md-12"},[_c('div',{staticClass:"skills-item__title"},[_c('img',{attrs:{"src":require("@/assets/svg/skills1.svg"),"alt":"Development"}}),_c('h6',[_vm._v(_vm._s(_vm.$t('skill1')))])])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"row mt-3 primary"},[_c('div',{staticClass:"col-xl-2 col-lg-4 col-md-12"},[_c('div',{staticClass:"skills-item__title"},[_c('img',{attrs:{"src":require("@/assets/svg/skills2.svg"),"alt":"Development"}}),_c('h6',[_vm._v(_vm._s(_vm.$t('skill2')))])])]),_c('div',{staticClass:"col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner"},[_c('p',[_vm._v(_vm._s(_vm.$t('management')))])])])]),_vm._m(5),_c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner"},[_c('ul',[_c('li',[_c('p',[_vm._v(_vm._s(_vm.$t('management1')))])]),_c('li',[_c('p',[_vm._v(_vm._s(_vm.$t('management2')))])]),_c('li',[_c('p',[_vm._v(_vm._s(_vm.$t('management3')))])])])])])]),_c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner"},[_c('p',[_vm._v(_vm._s(_vm.$t('management4')))])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner"},[_c('p',[_vm._v("Vue JS")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner"},[_c('ul',[_c('li',[_c('p',[_vm._v("Vue Router")])]),_c('li',[_c('p',[_vm._v("Vuex")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner has-one-sep"},[_c('p',[_vm._v("Java Script")]),_c('p',[_vm._v("HTML")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner has-two-sep"},[_c('ul',[_c('li',[_vm._v("Webpack")]),_c('li',[_vm._v("CSS")]),_c('li',[_vm._v("SCSS")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-9 col-md-6"},[_c('div',{staticClass:"row"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3"},[_c('div',{staticClass:"skills-item__block"},[_c('div',{staticClass:"skills-item__inner has-one-sep"},[_c('p',[_vm._v("Agile")]),_c('p',[_vm._v("Scrum")])])])])
}]

export { render, staticRenderFns }