<template>
  <main class="main">
    <ExperienceComponent></ExperienceComponent>
    <SkillsComponent></SkillsComponent>
    <EducationComponent></EducationComponent>
    <ProjectsComponent></ProjectsComponent>
    <ToolsComponent></ToolsComponent>
  </main>
</template>

<script>
import ExperienceComponent from './ExperienceComponent.vue';
import SkillsComponent from './SkillsComponent.vue';
import EducationComponent from './EducationComponent.vue';
import ProjectsComponent from './ProjectsComponent.vue';
import ToolsComponent from './ToolsComponent.vue';

export default {
  name: 'MainComponent',
  components: {
    ExperienceComponent,
    SkillsComponent,
    EducationComponent,
    ProjectsComponent,
    ToolsComponent,
  },
};
</script>
