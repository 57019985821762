<template>
  <div class="lang">
    <div class="lang-item">
      <div class="lang-item__icon">
        <img src="@/assets/svg/flag_rus.svg" alt="Russian" />
      </div>
      <div>
        <div class="lang-item__title">{{ $t('langRu') }}</div>
        <div class="lang-item__text">{{ $t('langRuStatus') }}</div>
      </div>
    </div>
    <div class="lang-item">
      <div class="lang-item__icon">
        <img src="@/assets/svg/flag_gb.svg" alt="English" />
      </div>
      <div>
        <div class="lang-item__title">{{ $t('langEn') }}</div>
        <div class="lang-item__text">{{ $t('langEnStatus') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguagesComponent',
};
</script>
