<template>
  <div class="stepline">
    <div class="stepline-circle"></div>
  </div>
</template>

<script>
export default {
  name: 'StepLineComponent',
};
</script>
