<template>
  <div class="wrap">
    <StepLineComponent></StepLineComponent>
    <div class="skills">
      <h2 class="skills__title">
        {{ $t('skillsTitle') }}
      </h2>
      <div class="skills-list mt-4">
        <div class="row">
          <div class="col-xl-2 col-lg-4 col-md-12">
            <div class="skills-item__title">
              <img src="@/assets/svg/skills1.svg" alt="Development" />
              <h6>{{ $t('skill1') }}</h6>
            </div>
          </div>
          <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner">
                <p>Vue JS</p>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner">
                <ul>
                  <li><p>Vue Router</p></li>
                  <li><p>Vuex</p></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner has-one-sep">
                <p>Java Script</p>
                <p>HTML</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner has-two-sep">
                <ul>
                  <li>Webpack</li>
                  <li>CSS</li>
                  <li>SCSS</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-6">
            <div class="row"></div>
          </div>
        </div>
        <div class="row mt-3 primary">
          <div class="col-xl-2 col-lg-4 col-md-12">
            <div class="skills-item__title">
              <img src="@/assets/svg/skills2.svg" alt="Development" />
              <h6>{{ $t('skill2') }}</h6>
            </div>
          </div>
          <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner">
                <p>{{ $t('management') }}</p>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 mt-lg-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner has-one-sep">
                <p>Agile</p>
                <p>Scrum</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner">
                <ul>
                  <li>
                    <p>{{ $t('management1') }}</p>
                  </li>
                  <li>
                    <p>{{ $t('management2') }}</p>
                  </li>
                  <li>
                    <p>{{ $t('management3') }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 mt-xl-0 mt-3">
            <div class="skills-item__block">
              <div class="skills-item__inner">
                <p>{{ $t('management4') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepLineComponent from './StepLineComponent.vue';
export default {
  name: 'SkillsComponent',
  components: {
    StepLineComponent,
  },
};
</script>
