<template>
  <div class="contacts">
    <h6></h6>
    <div class="contacts-item">
      <div class="contacts-item__icon">
        <img src="@/assets/svg/mail.svg" alt="Email" />
      </div>
      <div>
        <div class="contacts-item__title">{{ $t('email') }}</div>
        <div class="contacts-item__text">
          <a href="mailto:solovjevrv@gmail.com" target="_blank"
            >solovjevrv@gmail.com</a
          >
        </div>
      </div>
    </div>
    <div class="contacts-item">
      <div class="contacts-item__icon">
        <img src="@/assets/svg/website.svg" alt="Website" />
      </div>
      <div>
        <div class="contacts-item__title">{{ $t('website') }}</div>
        <div class="contacts-item__text">
          <a href="https://solovyovroman.ru" target="_blank"
            >https://solovyovroman.ru</a
          >
        </div>
      </div>
    </div>
    <div class="contacts-item">
      <div class="contacts-item__icon">
        <img src="@/assets/svg/phone.svg" alt="Phone" />
      </div>
      <div>
        <div class="contacts-item__title">{{ $t('phone') }}</div>
        <div class="contacts-item__text">
          <a href="tel:+79105010229" target="_blank">+7 910 501 02 29</a>
        </div>
      </div>
    </div>
    <div class="contacts-item">
      <div class="contacts-item__icon">
        <img src="@/assets/svg/location.svg" alt="Address" />
      </div>
      <div>
        <div class="contacts-item__title">{{ $t('address') }}</div>
        <div class="contacts-item__text">
          <p>{{ $t('addressValue') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactsComponent',
};
</script>
