<template>
  <div class="wrap">
    <StepLineComponent></StepLineComponent>
    <div class="education">
      <h2 class="education__title">
        {{ $t('educationTitle') }}
      </h2>
      <div class="row mt-4 mb-3">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="education-card">
            <div class="education-card__header">
              <img src="@/assets/svg/udemy-logo.svg" alt="Udemy" />
              <span>Udemy</span>
            </div>
            <span class="education-card__coursename">
              {{ $t('courseName1') }}
            </span>
            <span class="education-card__coursedate">
              {{ $t('courseDate1') }}
            </span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mt-3 mt-sm-0">
          <div class="education-card">
            <div class="education-card__header">
              <img src="@/assets/svg/gb-logo.svg" alt="Geekbrains" />
              <span>GeekBrains</span>
            </div>
            <span class="education-card__coursename">
              {{ $t('courseName2') }}
            </span>
            <span class="education-card__coursedate">
              {{ $t('courseDate2') }}
            </span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mt-3 mt-lg-0">
          <div class="education-card">
            <div class="education-card__header">
              <img src="@/assets/svg/udemy-logo.svg" alt="Udemy" />
              <span>Udemy</span>
            </div>
            <span class="education-card__coursename">
              {{ $t('courseName3') }}
            </span>
            <span class="education-card__coursedate">
              {{ $t('courseDate3') }}
            </span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
          <div class="education-card">
            <div class="education-card__header">
              <img src="@/assets/svg/gb-logo.svg" alt="GeekBrains" />
              <span>GeekBrains</span>
            </div>
            <span class="education-card__coursename">
              {{ $t('courseName4') }}
            </span>
            <span class="education-card__coursedate">
              {{ $t('courseDate4') }}
            </span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
          <div class="education-card">
            <div class="education-card__header">
              <img src="@/assets/svg/stepik-logo.svg" alt="Stepik" />
              <span>Stepik</span>
            </div>
            <span class="education-card__coursename">
              {{ $t('courseName5') }}
            </span>
            <span class="education-card__coursedate">
              {{ $t('courseDate5') }}
            </span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
          <div class="education-card">
            <div class="education-card__header">
              <img src="@/assets/svg/gb-logo.svg" alt="GeekBrains" />
              <span>GeekBrains</span>
            </div>
            <span class="education-card__coursename">
              {{ $t('courseName6') }}
            </span>
            <span class="education-card__coursedate">
              {{ $t('courseDate6') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepLineComponent from './StepLineComponent.vue';
export default {
  name: 'EducationComponent',
  components: {
    StepLineComponent,
  },
};
</script>
